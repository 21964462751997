import { useNavigation } from "@remix-run/react";
import { MouseEventHandler, useEffect, useRef } from "react";
import { useRadix } from "~/lib/rdt/radixProvider";

type Props = {
  title: string;
  onClick: MouseEventHandler;
};

export default function TransactionButton({ title, onClick }: Props) {
  const { transactionState, transaction } = useRadix();
  const prevTransactionStateRef = useRef<typeof transactionState>();
  const navigation = useNavigation();

  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevTransactionStateRef.current = transactionState;
  }, [transactionState]);

  return (
    <button
      type="submit"
      onClick={onClick}
      className="px-8 py-4 rounded-full mx-auto bg-white poppins-semibold text-[32px] text-black w-fit flex items-center justify-center mt-4"
    >
      {/* Transaction needs to be signed */}
      {navigation.state == "submitting" ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="mr-2 h-4 w-4 animate-spin"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          Recalculating
        </>
      ) : transactionState == "submitting" ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-3 animate-spin"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          Sign in the Radix Mobile wallet
        </>
      ) : null}

      {/* Transaction ended with an error */}
      {transactionState === "error" ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          {title}
        </>
      ) : null}

      {/* Transaction was signed */}
      {transactionState === "idle" &&
      prevTransactionStateRef.current === "submitting" &&
      transaction?.intentHash ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          Transaction Completed
        </>
      ) : null}

      {/* Initial state, where transaction can be initiated */}
      {transactionState == "idle" &&
      prevTransactionStateRef.current !== "submitting" &&
      navigation.state == "idle" ? (
        <>
          {/* <svg className="w-6 h-6 mr-3" viewBox="2 2 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M10.986 5.456a.608.608 0 0 0-.827 0 .564.564 0 0 0 0 .809l3.233 3.16H5.29a.579.579 0 0 0-.584.572.58.58 0 0 0 .584.572h8.102l-3.233 3.16a.564.564 0 0 0 0 .81c.11.107.257.167.414.167.155 0 .302-.06.413-.168l4.072-3.982a.778.778 0 0 0 0-1.119l-4.072-3.98z"
                     fill="currentColor"
                     fillRule="nonzero"
                  ></path>
               </svg> */}
          {title}
        </>
      ) : null}
    </button>
  );
}
